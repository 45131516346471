<template>
<div>
    <st-page layout="tab" :showHeader="false" customClass="doc-layout">
        <router-view></router-view>
    </st-page>
    </div>
</template>

<script>
export default {
    name: 'ApplicationDocumentsTab',
}
</script>